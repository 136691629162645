<template>
  <Popup
    :icon="IconGlobe"
    :iconHasBorder="true"
    customRightPosition="-right-[4px] lg:-right-[30px]"
    customTopPosition="top-[44px]"
  >
    <PopupItem
      v-for="(language, index) in languages"
      :class="index === languages.length - 1 ? '' : 'mb-[10px]'"
      :clickHandler="() => changeLanguage(language.key, language._id)"
      class="!px-[30px] !py-[5px] !h-[34px]"
    >
      <div class="flex items-center justify-start">
        <span class="text-cs-black text-sm dark:text-white">{{ language.name }}</span>
      </div>
    </PopupItem>
  </Popup>
</template>

<script lang="ts" setup>
import IconGlobe from 'assets/icons/globe.svg'
import {LANGUAGE_COOKIE} from '~/constants/i18n'
import {useUserUpdatePreferredLanguage} from '~/composables/user'
import {useUsersGetLanguages} from '~/composables/language'

const {locale} = useI18n()

const languageCookie = useCookie(LANGUAGE_COOKIE)
const {$showErrorToast, $setI18nLanguage} = useNuxtApp()

const {error: errorLanguages, data: languages} = await useUsersGetLanguages()
if (errorLanguages && errorLanguages.value) {
  $showErrorToast(errorLanguages)
}

const changeLanguage = async (languageKey: string, _id: string) => {
  // Update current language
  locale.value = languageKey

  // Set lang attr to html tag
  $setI18nLanguage(languageKey)

  // Update cookie value
  languageCookie.value = languageKey

  // Update preference for this user
  await useUserUpdatePreferredLanguage(_id)
}
</script>
